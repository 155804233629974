<template>
  <div class="history-org-drawer" :style="containerStyle" ref="drawerContainer">
    <div class="drawer-content" :style="contentStyle">
      <div v-for="colIndex in cols" :key="colIndex" class="column">
        <div v-for="rowIndex in rows" :key="rowIndex" class="cell" :style="cellStyle">
          <template v-if="drawCells[colIndex - 1] && drawCells[colIndex - 1][rowIndex - 1]">
            <img :src="getImagePath(drawCells[colIndex - 1][rowIndex - 1])"
                 :alt="getAltText(drawCells[colIndex - 1][rowIndex - 1].result)"
                 :style="imageStyle" />
            <div v-if="drawCells[colIndex - 1][rowIndex - 1].tieCount > 0" class="tie-count" :class="{'mobile': $API.util.isMobile()}">
              {{ drawCells[colIndex - 1][rowIndex - 1].tieCount }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const BREAK_LEFTORRIGHT = 1;

export default {
  name: 'HistoryOrgDrawer',
  props: {
    reloadInterval: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      drawCells: [],
      pCount: 0,
      bCount: 0,
      tCount: 0,
      ppCount: 0,
      bpCount: 0,
      origins: [],
      cols: 0,
      rows: 6,
      cellWidth: 0,
      cellHeight: 0,
      deltaCols: 0,
      breakLeftOrRight: BREAK_LEFTORRIGHT,

      reloadTimer: null
    }
  },
  mounted() {
    this.startReloadTimer()
  },
  beforeUnmount() {
    this.stopReloadTimer()
  },
  computed: {
    containerStyle() {
      return {
        width: `100%`,
        height: `100%`,
      }
    },
    contentStyle() {
      return {
        width: `${this.drawCells.length * this.cellWidth}px`,
      }
    },
    cellStyle() {
      return {
        width: `${this.cellWidth}px`,
        height: `${this.cellHeight}px`,
      }
    },
    imageStyle() {
      return {
        width: '28px',
        height: `${this.cellHeight}px`,
      }
    }
  },
  methods: {
    init(width, height, cols) {
      this.cols = cols
      this.colsOrigin = cols
      this.cellWidth = width / this.cols;
      this.cellHeight = height / this.rows;
      this.drawCells = Array(this.cols).fill().map(() => Array(this.rows).fill(null));
      this.origins = [];
    },
    redraw(hists) {
      this.updateOriginal(hists);
      this.drawCells = [];
      this.deltaCols = 0;
      let iPrevResult = -1;
      let curPos = { row: 0, col: 0 };

      for (let iOrgCol = 0; iOrgCol < this.origins.length; iOrgCol++) {
        for (let iOrgRow = 0; iOrgRow < this.origins[iOrgCol].length; iOrgRow++) {
          if (iPrevResult >= 0) {
            this.getNextRowCol(iPrevResult == this.origins[iOrgCol][iOrgRow].result, curPos);
          }
          if (this.drawCells[curPos.col] === undefined) this.drawCells[curPos.col] = [];

          this.drawCells[curPos.col][curPos.row] = this.origins[iOrgCol][iOrgRow];
          iPrevResult = this.origins[iOrgCol][iOrgRow].result;
        }
      }
      this.cellWidth = this.$refs.drawerContainer.parentElement.clientWidth / this.colsOrigin; // 셀 크기를 매번 새로 구해 칸 흐름 현상을 해결
      this.cols = this.colsOrigin < this.drawCells.length ? this.drawCells.length : this.colsOrigin; // 최종 cols에서 새로운 칸 늘어나도록 컬럼 재계산

      // 스크롤을 맨 끝으로 이동
      this.$nextTick(() => {
        const container = this.$refs.drawerContainer;
        container.scrollLeft = container.scrollWidth;
      });
    },
    updateOriginal(hists) {
      this.origins = [];
      let iRow = 0;
      let iCol = -1;
      let iPrevResult = -1;
      let iPrevTieCount = 0, iTieCount = 0;

      this.pCount = 0;
      this.bCount = 0;
      this.tCount = 0;
      this.ppCount = 0;
      this.bpCount = 0;

      for (let i = 0; i < hists.length; i++) {
        let iResult = -1;
        if (hists[i].startsWith('1')) iResult = 1;
        else if (hists[i].startsWith('2')) iResult = 2;
        else if (hists[i].startsWith('3')) iResult = 0;

        const ppair = hists[i].endsWith('2') || hists[i].endsWith('3');
        const bpair = hists[i][1] === '1' || hists[i][1] === '3';

        if (iResult === 1) this.bCount++
        if (iResult === 2) this.tCount++
        if (iResult === 0) this.pCount++
        if (ppair) this.ppCount++
        if (bpair) this.bpCount++

        if (iResult !== 2) {
          if (iPrevResult !== iResult) {
            iCol++;
            iRow = 0;
          } else {
            iRow++;
          }

          iTieCount = 0;
          if (iPrevTieCount > 0) {
            iCol = iRow = 0;
            this.origins = [];
            iTieCount = iPrevTieCount;
          }
          iPrevTieCount = -1;

          if (this.origins[iCol] === undefined) this.origins[iCol] = [];
          this.origins[iCol][iRow] = { tieCount: iTieCount, result: iResult, ppair, bpair };

          iPrevResult = iResult;
        } else {
          if (iPrevTieCount >= 0) iPrevTieCount++;
          if (iCol < 0) {
            this.origins[0] = [{ tieCount: 1, result: 2, ppair, bpair }];
            iPrevResult = 2;
            iCol = 0;
          } else {
            this.origins[iCol][iRow].tieCount++;
          }
        }
      }
    },
    getNextRowCol(tf, pos) {
      if (tf) {
        pos.row++;
        if (pos.row >= this.rows || void 0 !== this.drawCells[pos.col][pos.row] || this.deltaCols > 0) {
          pos.row--;
          pos.col += this.breakLeftOrRight;
          this.deltaCols++;
          // 만일 왼쪽으로 꺽이다가 왼쪽 끝에 도달햇거나 혹은 꺽이여가는 쪽에 이미 셀이 존재하면 반대방향으로 뻗어나간다.
          if (pos.col < 1 || (this.drawCells[pos.col] !== void 0 && this.drawCells[pos.col][pos.row] !== void 0) ||
              (this.drawCells[pos.col + this.breakLeftOrRight] !== void 0 && this.drawCells[pos.col + this.breakLeftOrRight][pos.row] !== void 0)) {
            // 이미 꺾인 셀들을 반대방향으로 돌린다.
            for (let i = 1; i < this.deltaCols; i++) {
              const cell = this.drawCells[pos.col - i * this.breakLeftOrRight][pos.row];
              if (this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight] === void 0) this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight] = [];
              this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight][pos.row] = cell;
              this.drawCells[pos.col - i * this.breakLeftOrRight][pos.row] = undefined;
            }
            // 계산할때 이용하는 변수는 반대로 설정한다.
            this.breakLeftOrRight = this.breakLeftOrRight == 1 ? -1 : 1;

            pos.col += 2 * this.deltaCols * this.breakLeftOrRight;
          }
        }
      } else {
        pos.col = pos.col - this.breakLeftOrRight * this.deltaCols + 1;
        pos.row = 0;
        this.deltaCols = 0;
        this.breakLeftOrRight = BREAK_LEFTORRIGHT;
      }
    },
    getImagePath(cell) {

      let a = ''
      if (cell.result === 0) a = '3'
      else if (cell.result === 1) a = '1'
      else if (cell.result === 2) a = '2'

      if (cell.ppair === true && cell.bpair === true) {
        a += '3'
      } else {
        if (cell.bpair) a += '1'
        if (cell.ppair) a += '2'
        if (cell.bpair === false && cell.ppair === false) a += '0'
      }

      return `/imgs/history/br_${a}.png`;
    },
    getAltText(result) {
      return result === 0 ? 'Player' : result === 1 ? 'Banker' : 'Tie';
    },
    getOrigins() {
      return this.origins;
    },

    // [이미지 로딩 실패 시 리로드]
    startReloadTimer() {
      this.reloadTimer = setInterval(() => {
        this.reloadBrokenImages()
      }, this.reloadInterval)
    },
    stopReloadTimer() {
      if (this.reloadTimer) {
        clearInterval(this.reloadTimer)
      }
    },
    reloadBrokenImages() {
      if (!this.$refs.drawerContainer) return

      const images = this.$refs.drawerContainer.getElementsByTagName('img')
      for (let img of images) {
        if (!img.complete) {
          // 이미지가 로드되지 않았거나 너비가 0인 경우 (로딩 실패)
          const currentSrc = img.src
          img.src = ''
          img.src = currentSrc
          console.log('Reloading image:', currentSrc)
        }
      }
    }
  }
}
</script>

<style scoped>
.history-org-drawer {
  overflow-x: auto;
}
.drawer-content {
  display: flex;
}
.column {
  display: flex;
  flex-direction: column;
}
.cell {
  position: relative;
  border: 1px solid #eee;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transform: scale(1.03);
}
img {
  max-width: 100%;
  max-height: 100%;
}
.tie-count {
  position: absolute;
  top: 43%;
  left: 48%;
  transform: translate(-50%, -50%);
  color: #5baf00;
  font-weight: bold;
  font-size: 18px;
}

.mobile.tie-count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #5baf00;
  font-weight: bold;
  font-size: 12px;
}
</style>