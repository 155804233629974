<template>
  <div class="bet_history" id="bet_history" style="overflow: scroll;" @touchstart="handleTouch">
    <img src="/ps/images/icon_close.png" class="close" id="closeBetHstyPop" @click="close">

    <h1>베팅이력</h1>
    <table>
      <thead>
      <tr>
        <th>베팅번호</th>
        <th>게임번호</th>
        <th>게임명</th>
        <th>테이블</th>
        <th>회차</th>
        <th>플레이어</th>
        <th>뱅커</th>
        <th>플레이어</th>
        <th>뱅커</th>
        <th>타이</th>
        <th>플.페어</th>
        <th>뱅.페어</th>
        <th>베팅금액</th>
        <th>당첨금액</th>
        <th>합산금액</th>
        <th>베팅전 금액</th>
        <th>정산후 금액</th>
        <th>베팅상태</th>
        <th>정산상태</th>
        <th>게임결과</th>
        <th>베팅시간</th>
      </tr>
      </thead>

      <tbody>

      <tr v-if="!documents || documents.length === 0">
        <td colspan="21" style="color: white;height: 50px;vertical-align: middle;text-align: center;">No matching
          records
        </td>
      </tr>

      <tr v-for="record in documents" v-bind:key="record.id" v-else>

        <td>{{ record.num }}</td>
        <td>{{ record.round.num }}</td>
        <td>{{ record.company.name }}</td>
        <td>{{ record.table.name }}</td>
        <td>{{ record.round.round_count || 1 }}</td>
        <td style="">
          <div v-if="record.round.data && record.round.data.cards.playerCards">
<!--            <span v-if="record.round.data.cards.playerCards[2]"><img :src="`/card/${record.round.data.cards.playerCards[2].shape + record.round.data.cards.playerCards[2].number}.png`" style="width: 32px; margin-right: 2px;"/></span>-->
<!--            <span v-if="record.round.data.cards.playerCards[1]"><img :src="`/card/${record.round.data.cards.playerCards[1].shape + record.round.data.cards.playerCards[1].number}.png`" style="width: 32px; margin-right: 2px;"/></span>-->
<!--            <span v-if="record.round.data.cards.playerCards[0]"><img :src="`/card/${record.round.data.cards.playerCards[0].shape + record.round.data.cards.playerCards[0].number}.png`" style="width: 32px; margin-right: 2px;"/></span>-->

          </div>

          <span style="color: skyblue;">{{ record.round?.data?.result?.playerNumber || 0 }}</span>
        </td>
        <td style="">
          <span style="color: red;">{{ record.round?.data?.result?.bankerNumber || 0 }}</span>
          <div v-if="record.round.data && record.round.data.cards.bankerCards">
<!--            <span v-if="record.round.data.cards.bankerCards[0]"><img :src="`/card/${record.round.data.cards.bankerCards[0].shape + record.round.data.cards.bankerCards[0].number}.png`" style="width: 32px; margin-right: 2px;"/></span>-->
<!--            <span v-if="record.round.data.cards.bankerCards[1]"><img :src="`/card/${record.round.data.cards.bankerCards[1].shape + record.round.data.cards.bankerCards[1].number}.png`" style="width: 32px; margin-right: 2px;"/></span>-->
<!--            <span v-if="record.round.data.cards.bankerCards[2]"><img :src="`/card/${record.round.data.cards.bankerCards[2].shape + record.round.data.cards.bankerCards[2].number}.png`" style="width: 32px; margin-right: 2px;"/></span>-->
          </div>
        </td>
        <td>{{ record.target.p.toFloat().toThousands() }}</td>
        <td>{{ record.target.b.toFloat().toThousands() }}</td>
        <td>{{ record.target.t.toFloat().toThousands() }}</td>
        <td>{{ record.target.pp.toFloat().toThousands() }}</td>
        <td>{{ record.target.bp.toFloat().toThousands() }}</td>
        <td>{{ record.amount.toFloat().toThousands() }}</td>
        <td>{{ record.payout.toFloat().toThousands() }}</td>
        <td>{{ (record.payout - record.amount).toFloat().toThousands() }}</td>
        <td>{{ record.before_amount.toFloat().toThousands() }}</td>
        <td>{{ record.after_amount.toFloat().toThousands() }}</td>
        <td>
          <span style="color: blue;" v-if="record.state === 'bet'">베팅</span>
          <span style="color: green;" v-if="record.state === 'complete'">완료</span>
          <span style="color: red;" v-if="record.state === 'cancel'">취소</span>
        </td>
        <td>
          <span style="color: blue;" v-if="record.round.state === 'bet'">베팅</span>
          <span style="color: green;" v-if="record.round.state === 'close'">정상</span>
          <span style="color: red;" v-if="record.round.state === 'cancel'">취소</span>
        </td>
        <td>

          <span style="color: red;" v-if="record.round.result && (record.round.result === '10')">뱅커</span>
          <span style="color: red;" v-if="record.round.result && (record.round.result === '11')">뱅커 / 뱅커 페어</span>
          <span style="color: red;" v-if="record.round.result && (record.round.result === '12')">뱅커 / 플레이어 페어</span>
          <span style="color: red;" v-if="record.round.result && (record.round.result === '13')">뱅커 / 뱅커 + 플레이어 페어</span>

          <span style="color: green;" v-if="record.round.result && (record.round.result === '20')">타이</span>
          <span style="color: green;" v-if="record.round.result && (record.round.result === '21')">타이 / 뱅커 페어</span>
          <span style="color: green;" v-if="record.round.result && (record.round.result === '22')">타이 / 플레이어 페어</span>
          <span style="color: green;" v-if="record.round.result && (record.round.result === '23')">타이 / 뱅커 + 플레이어 페어</span>

          <span style="color: skyblue;" v-if="record.round.result && (record.round.result === '30')">플레이어</span>
          <span style="color: skyblue;" v-if="record.round.result && (record.round.result === '31')">플레이어 / 뱅커 페어</span>
          <span style="color: skyblue;" v-if="record.round.result && (record.round.result === '32')">플레이어 / 플레이어 페어</span>
          <span style="color: skyblue;" v-if="record.round.result && (record.round.result === '33')">플레이어 / 뱅커 + 플레이어 페어</span>
        </td>
        <td v-html="new Date(record.created_at).toLocalTime('YYYY-MM-DD HH:mm:ss')"></td>
      </tr>

      </tbody>
    </table>
    <div class="paging pagination-wrap">
      <div class="text-center">
        <v-pagination
            class="justify-content-center mb-2"
            v-model="page"
            :pages=(Math.ceil(total/option.limit))
            :range-size="3"
            active-color="#DCEDFF"
            @update:modelValue="searchByPage"
        />
      </div>
    </div>
  </div>

</template>

<script>
import VPagination from "@hennge/vue3-pagination"
import '/src/assets/styles/pagination.default.css'
// import DatePicker from "@vuepic/vue-datepicker"

export default {
  name: 'bet-history-component',
  components: {
    VPagination,
    // DatePicker
  },
  emits: [
    'closeBets'
  ],
  props: {
    company: Object,
    table: Object,
  },
  data() {
    return {
      option: {
        company: '',
        table: '',
        search_type: '',
        search_text: '',
        begin_date: '',
        end_date: '',
        offset: 0,
        limit: 25,
        order_by: {created_at: 'DESC'}
      },
      documents: [],
      total: 0,
      page: 1,
      showOverlay: true
    }
  },
  methods: {
    open: function () {
      this.documents = []
      this.total = 0
      this.page = 1
      this.option = {
        search_type: '',
        search_text: '',
        begin_date: '',
        end_date: '',
        offset: 0,
        limit: 10,
        order_by: {created_at: 'DESC'}
      }

      this.list().then()
    },
    close() {
      this.documents = []
      this.page = 1
      this.total = 0

      this.$emit('closeBets')

      this.$API.util.playSoundClick()
    },
    searchByPage: function (page) {
      this.page = page
      this.list().then()
    },

    handleTouch() {
      const obj = document.getElementById('bet-history')
      if (obj.classList.contains('overlay-active')) {
        obj.classList.remove('overlay-active');
      }
    },

    async list() {
      const loader = this.$loading.show({container: this.$refs['history-wrapper']})
      try {
        // 전체 베팅 내역을 조회해야 하여 company, table을 null 로 요청 한다
        this.option.company = '' //this.company ? this.company._id : ''
        this.option.table = ''

        this.option.offset = (this.page * this.option.limit) - this.option.limit

        const result = await this.$API.game.betHistory(this.option)
        if (result.code !== 'OK') {
          this.$refs['modal_error'].open({title: result.code, content: result.message})
          return
        }

        let count = result.total - this.option.offset
        for (const key in result.documents) {
          const document = result.documents[key]
          document.num = count
          count--
        }

        this.documents = result.documents
        this.total = result.total
      } catch (e) {
        this.$refs['modal_error'].open({title: 'EXCEPTION', content: e.message})
      } finally {
        loader.hide()
      }
    }
  }
}
</script>