<template>
  <div class="room-history-panel">
    <HistoryOrgDrawer ref="drawerOrg"/>
  </div>
</template>

<script>
import HistoryOrgDrawer from '@/components/history/HistoryOrgDrawer.vue'

export default {
  name: 'lobby-history-panel-component',
  components: {
    HistoryOrgDrawer
  },
  props: {
    lobbyColType: Number,
    lobbyHistoryType: Number,
    room: Object,
    histories: Object
  },
  data () {
    return {

    }
  },
  computed: {
    history: function () {
      return this.histories
    }
  },
  methods: {
    redraw: function (history) {
      this.$refs.drawerOrg.redraw(history)
    },
    updateHistory: function () {
      this.redraw(this.history);
    },
    onHistoryUpdate: function () {
      this.updateHistory();
    },
    getHistoryCount () {
      return {
        b: this.$refs.drawerOrg.bCount,
        p: this.$refs.drawerOrg.pCount,
        t: this.$refs.drawerOrg.tCount
      }
    }
  },
  created: function () {
    // this.$root.$on(EVENT_HISTORY_UPDATE, this.onHistoryUpdate);

  },
  unmounted: function () {
    // this.$root.$off(EVENT_HISTORY_UPDATE, this.onHistoryUpdate);

  },
  mounted: function () {
    this.$refs.drawerOrg.init(this.$el.clientWidth, this.$API.util.isMobile() ? 100 : 144, 25);

    this.updateHistory();
  }
}
</script>

<style scoped>
.room-history-panel {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.history-container {
  display: flex;
}

.history-left {
  width: 571px; /* HistorySixDrawer의 너비 */
}

.history-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1008px; /* HistoryOrgDrawer의 전체 너비 */
}

.history-org {
  height: 144px; /* HistoryOrgDrawer의 높이 */
}

.history-123-container {
  display: flex;
  flex-direction: column;
}

.history-123-full {
  width: 100%;
  height: 72px; /* History123Drawer (type 1)의 높이 */
}

.history-123-bottom {
  display: flex;
}

.history-123-half {
  width: 504px; /* History123Drawer (type 2, 3)의 너비 */
  height: 72px; /* History123Drawer (type 2, 3)의 높이 */
}
</style>